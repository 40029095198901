/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* Choose all input elements that have the attribute: type="radio" and make them disappear.*/
.divGroup input[type="radio"] {
  display:none;
}
.divGroup{
  text-align: center;
}
/* The label is what's left to style. 
As long as its 'for' attribute matches the input's 'id', it will maintain the function of a radio button. */
.divGroup label {
  padding: 1em 4em;
  display: inline-block;
  border: 1px solid grey;
  cursor: pointer;
}

.divGroup .blank-label {
  display: none;
}
.list{
  background-color: gainsboro;
}
.list1 {
  background-color: green;
}

/* The '+' is the adjacent sibling selector.
It selects what ever element comes right after it,
as long as it is a sibling. */
.divGroup input[type="radio"]:checked + label {
  background:#163c7c;
  color: #fff;
}



.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}
.test{
  margin-top: 30px;
  padding: 1%;
}
.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}


.table-pagination {
  display: flex;
  justify-content: center;
  background-color: darkgrey;
  padding-top: 15px;

}
.pagination-container {
  display: inline-block;
  position: relative;
}
ul.paginationBttns {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
ul.paginationBttns a {
  padding: 10px 20px;
}
.page-margin {
  margin-right: 15px;
}

/* .active{
  color: red;
} */

/* 
.menu li{
  display:inline;
  margin: 15px;
  }
  .active {
  background-color: #20BF55;
  padding:5px;
  }
  .hover {
  background-color: #89de6f;
  padding:5px;
  } */
  .search:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
 


input[type=number].no-spinner::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
    
}


a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #333;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
max-width: 100%;
height: auto;
}
/*--blog----*/

.sec-title{
position:relative;
margin-bottom:70px;
}

.sec-title .title{
position: relative;
display: block;
font-size: 16px;
line-height: 1em;
color: #ff8a01;
font-weight: 500;
background: rgb(247,0,104);
background: -moz-linear-gradient(to left, rgba(247,0,104,1) 0%, rgba(68,16,102,1) 25%, rgba(247,0,104,1) 75%, rgba(68,16,102,1) 100%);
background: -webkit-linear-gradient(to left, rgba(247,0,104,1) 0%,rgba(68,16,102,1) 25%,rgba(247,0,104,1) 75%,rgba(68,16,102,1) 100%);
background: linear-gradient(to left, rgba(247,0,104) 0%,rgba(68,16,102,1) 25%,rgba(247,0,104,1) 75%,rgba(68,16,102,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F70068', endColorstr='#441066',GradientType=1 );
color: transparent;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-transform: uppercase;
letter-spacing: 5px;
margin-bottom: 15px;
}

.sec-title h2{
position:relative;
display: inline-block;
font-size:48px;
line-height:1.2em;
color:#1e1f36;
font-weight:700;
}

.sec-title .text{
position: relative;
font-size: 16px;
line-height: 28px;
color: #888888;
margin-top: 30px;
}

.sec-title.light h2,
.sec-title.light .title{
color: #ffffff;
-webkit-text-fill-color:inherit; 
}
.pricing-section {
position: relative;
padding: 100px 0 80px;
overflow: hidden;
}
.pricing-section .outer-box{
max-width: 1100px;
margin: 0 auto;
}


.pricing-section .row{
margin: 0 -30px;
}

.pricing-block{
position: relative;
padding: 0 30px;
margin-bottom: 40px;
}

.pricing-block .inner-box{
position: relative;
background-color: #ffffff;
box-shadow: 0 20px 40px rgba(0,0,0,0.08);
padding: 0 0 30px;
max-width: 370px;
margin: 0 auto;
border-bottom: 20px solid #40cbb4;
}

.pricing-block .icon-box{
position: relative;
padding: 50px 30px 0;
background-color: #40cbb4;
text-align: center;
}

.pricing-block .icon-box:before{
position: absolute;
left: 0;
bottom: 0;
height: 75px;
width: 100%;
border-radius: 50% 50% 0 0;
background-color: #ffffff;
content: "";
}


.pricing-block .icon-box .icon-outer{
position: relative;
height: 150px;
width: 150px;
background-color: #ffffff;
border-radius: 50%;
margin: 0 auto;
padding: 10px;
}

.pricing-block .icon-box i{
position: relative;
display: block;
height: 130px;
width: 130px;
line-height: 120px;
border: 5px solid #40cbb4;
border-radius: 50%;
font-size: 50px;
color: #40cbb4;
-webkit-transition:all 600ms ease;
-ms-transition:all 600ms ease;
-o-transition:all 600ms ease;
-moz-transition:all 600ms ease;
transition:all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i{
transform:rotate(360deg);
}

.pricing-block .price-box{
position: relative;
text-align: center;
padding: 10px 20px;
}

.pricing-block .title{
position: relative;
display: block;
font-size: 24px;
line-height: 1.2em;
color: #222222;
font-weight: 600;
}

.pricing-block .price{
display: block;
font-size: 30px;
color: #222222;
font-weight: 700;
color: #40cbb4;
}


.pricing-block .features{
position: relative;
max-width: 200px;
margin: 0 auto 20px;
}

.pricing-block .features li{
position: relative;
display: block;
font-size: 14px;
line-height: 30px;
color: #848484;
font-weight: 500;
padding: 5px 0;
padding-left: 30px;
border-bottom: 1px dashed #dddddd;
}
.pricing-block .features li:before {
position: absolute;
left: 0;
top: 50%;
font-size: 16px;
color: #2bd40f;
-moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
display: inline-block;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1;
content: "\f058";
font-family: "Font Awesome 5 Free";
margin-top: -8px;
}
.pricing-block .features li.false:before{
color: #e1137b;
content: "\f057";
}

.pricing-block .features li a{
color: #848484;
}

.pricing-block .features li:last-child{
border-bottom: 0;
}

.pricing-block .btn-box{
position: relative;
text-align: center;
}

.pricing-block .btn-box a{
position: relative;
display: inline-block;
font-size: 14px;
line-height: 25px;
color: #ffffff;
font-weight: 500;
padding: 8px 30px;
background-color: #40cbb4;
border-radius: 10px;
border-top:2px solid transparent;
border-bottom:2px solid transparent;
-webkit-transition: all 400ms ease;
-moz-transition: all 400ms ease;
-ms-transition: all 400ms ease;
-o-transition: all 400ms ease;
transition: all 300ms ease;
}

.pricing-block .btn-box a:hover{
color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a{
color:#40cbb4;
background:none;
border-radius:0px;
border-color:#40cbb4;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .inner-box{
border-color: #1d95d2;
}

.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box{
background-color: #1d95d2;
}

.pricing-block:nth-child(2) .inner-box:hover .btn-box a{
color:#1d95d2;
background:none;
border-radius:0px;
border-color:#1d95d2;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .price{
color: #1d95d2;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .inner-box{
border-color: #ffc20b;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box{
background-color: #ffc20b;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .price{
color: #ffc20b;
}

.pricing-block:nth-child(3) .inner-box:hover .btn-box a{
color:#ffc20b;
background:none;
border-radius:0px;
border-color:#ffc20b;
}

.mar{
  margin-top: 10px;
}
.butleft{
  margin-left:97%;
}
.pad{
  padding-right: 24%;
  margin-left: 17%;
}
.padi{
  /* padding-right: 29%; */
    margin-top: 0%;
    margin-left: 9%;
    padding-right: 15%;
}
.subscribe{
  margin-left: 30%;
}
.sub{
  padding-right: 65%;
  padding-right: 39%;
}
.sub1{

  font-weight: bold;
    font-size: 1rem;
    padding-right: 50%;
}
.check{
 font-size: 10%;
}
.pad{
  padding-left: 5%;
}
.onlineimg{
  margin-right: 40%;
 
}

.signupimg{
  margin-left: 65%;
  width: 30%;
}

.customercon{
  padding-top: 30px;
}
.agentrequestbtn{
  margin-top: 10%;
}

.custsupportrow{
  margin-top: 40px;
}

.custcombtn{
  margin-left: 90%;
}

.jobdescimg{
  margin-left: 30%;
}

.textalign{
  text-align: center;
}

.jobdesc{
  margin-top: 35px;
}

.choosejob{
margin-left: 75%;
}

.digiimg{
  margin-left: 30%;
}

.digibtn{
    margin-left: 39%;
    margin-top: 40px;
    width: 20%;
    text-decoration: none;
}

.backgroundimg{
  background-size: cover;
  background-repeat:no-repeat; 
}
.signsidebar{
margin-right: 50%;
}
.jobcard{
margin-top: 30px;
}

.empcard{
  margin-top: 36%;
}

.signupclr{
  color: red;
}

.alignstart{
  text-align: start;
}

.jobdescleft{
  margin-left: 20%;
}

.paymentbutton{
margin-top: 30px;
width: 10%;
}
.emplogbtn{
margin-left: 42px;
}

.loginrow{
  margin-top: 30px;
  border-radius: 10px;
  /* box-shadow : 10px 10px 1000px 10px gainsboro; */
  box-shadow : 10px 10px 1000px 10px blue;
}

.loginrow1{
  margin-top: 30px;
  border-radius: 10px;
  /* box-shadow : 10px 10px 1000px 10px gainsboro; */
  box-shadow : 10px 10px 100px 10px blue;
}
.martop20px{
  margin-top: 20px;
}

.accordion {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.accordion-header h2 {
  margin: 0;
  font-size: 20px;
}

.accordion-header span {
  font-size: 20px;
  font-weight: bold;
}

.accordion-content {
  padding: 10px;
}
body {
  overflow-x: hidden;
  cursor: url("https://www.google.com/imgres?q=cursor&imgurl=https%3A%2F%2Fstatic-00.iconduck.com%2Fassets.00%2Fcursor-icon-1024x1024-1h8hxibl.png&imgrefurl=https%3A%2F%2Ficonduck.com%2Ficons%2F295307%2Fcursor&docid=e2my1ZNNZZQ-ZM&tbnid=KeJIoDeksszlKM&vet=12ahUKEwi3xPe58KyJAxVo1zgGHf85OVgQM3oECC0QAA..i&w=1024&h=1024&hcb=2&ved=2ahUKEwi3xPe58KyJAxVo1zgGHf85OVgQM3oECC0QAA"),auto;
}


.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.carousel img {
  height: 400px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.carousel img.active {
  opacity: 1;
}

.Auth-form-content i {
  position: absolute;
  top: 272px;
  /* right: 10px; */
  margin-left: 246px;
  transform: translateY(-50%);
  cursor: pointer;
}
.forget i{
  position: absolute;
  top: 327px;
  margin-left: 245px;
  transform: translateY(-50%);
  cursor: pointer;
}
body select {
  cursor: pointer;
}
.backbuttonclr{
  color: #fff;
  text-decoration: none;
}

.login_letter{
  margin-top: 20px;
  color:blue;
}
/* 
.banner-sec {
	background: url(https://static.pexels.com/photos/33972/pexels-photo.jpg) no-repeat left bottom;
	background-size: cover;
	min-height: 500px;
	border-radius: 0 10px 10px 0;
	padding: 0;
} */

.signin-img {

width: 750px !important;
border-radius: 0px 5px 5px 0px !important;

}
.sign-img-1{
  border-radius: 0px 5px 5px 0px !important;
}

.loginbtn{
  width:130px;
}

.a-forgot{
  font-size: 15px;
}

.marlef59{
  margin-left: 59%;
}
.marlef12{
  margin-left: 12%;
}

.background-text {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 50px;
  opacity: 0.1;
  z-index: -1;
}
.alignleft td{
  text-align: left;
}





.owl-carousel,
.owl-carousel div {
  height: 100%;
}

.selection {
  position: relative;
}

.selection-body{
  margin: 0;
  line-height: 100%;
}

.selection-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.item--one {
  background: url("https://images.unsplash.com/photo-1573060493914-a7278e31d806?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80")
    no-repeat center / cover;
}

.item--two {
  background: url("https://images.unsplash.com/photo-1497098478417-d823ef2eed8e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80")
    no-repeat center / cover;
}

.item--three {
  background: url("https://images.unsplash.com/photo-1632046813055-eaef29c779f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80")
    no-repeat center / cover;
}

.item--four {
  background: url("https://images.unsplash.com/photo-1596774468032-915cdd39ea39?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80")
    no-repeat center / cover;
}

.item h1 {
  color: #ffffff;
  font-size: 4rem;
}

.owl-dots {
  height: auto !important;
  position: absolute;
  inset: auto 50% 20px;
  display: flex;
  width: fit-content;
  transform: translateX(-50%);
}

.owl-dot span {
  width: 30px !important;
  height: 5px !important;
  border-radius: 2.5px !important;
}
/* .watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  opacity: 0.5;
  font-size: 5em;
  font-weight: bold;
  color: #ccc;
} */
.watermark {
  position: absolute;
  top: 100%;
  left: 59%;
  transform: translate(-60%, -60%) rotate(-45deg);
  opacity: 0.5;
  font-size: 4em;
  font-weight: bold;
  color: #ccc;
}

/* input[type="text"] {
  text-transform: capitalize;
}
textarea {
  text-transform: capitalize;
} */

.text-center a{

  color: white;
  text-decoration: none;
}



/* <footer> */

.login_footer {
  background: linear-gradient(105deg,#6e99e6 ,#0230ff);
  /* padding-top: 80px; */
  padding-bottom: 40px;
}
.login_single_footer{}
@media only screen and (max-width:768px) { 
.login_single_footer{margin-bottom:30px;}
}
.login_single_footer h4 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}
.login_single_footer h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: #fff;
    margin-top: 20px;
}
.login_single_footer p{color:#fff;}
.login_single_footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #fff;
}
.login_single_footer ul li{}
.login_single_footer ul li a {
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 36px;
    font-size: 15px;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: bold;
}
.login_single_footer ul li a:hover { color: #fff; }
/* .login_single_footer ul li a:hover { color: #ff3666; } */

.login_single_footer_address{}
.login_single_footer_address ul{}
.login_single_footer_address ul li{color:#fff;}
.login_single_footer_address ul li span {
    font-weight: 400;
    color: #fff;
    line-height: 28px;
}
.contact_social ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

/* Popup Overlay */
.login_popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_popup-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Popup Content */
.login_popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  max-height: 500px;
  overflow-y: auto;
  width: 1100px;
  margin: 0 auto; 
}
.login_popup1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  max-height: 300px;
  overflow-y: auto;
  width: 400px;
  margin: 0 auto; 
}
.login_popup::-webkit-scrollbar {
  width: 8px;
}

.login_popup::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.login_popup::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.login_popup::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.login_popup h2 {
  margin-bottom: 10px;
}

.login_popup p {
  margin-bottom: 20px;
  text-align: initial;
}

.login_popup button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.login_popup1 button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login_popup button:hover {
  background-color: #45a049;
}
.login_popup1 button:hover {
  background-color: #45a049;
}

.login_popup button:focus {
  outline: none;
}
.login_popup1 button:focus {
  outline: none;
}

.footerULlist{
  margin-left: 30px;
}

.important-align {
  text-align: left !important;
}

.marlefauto{
  margin-left: auto;
}


/* src/App.css */
.ant-table-wrapper {
  margin: 20px;
}

/* Optional: Add some styling for the filter dropdown */
.ant-table-filter-dropdown {
  padding: 8px;
}

/* Optional: Add some styling for the filter input */
.ant-input {
  margin-bottom: 8px;
}

/* Optional: Add some styling for the search button */
.ant-btn-primary {
  margin-right: 8px;
}

/* Optional: Add some styling for the reset button */
.ant-btn-default {
  margin-left: 8px;
}


.ag-header-cell {
  border-right: 1px solid #9d9b9b !important;
}
.ag-cell {
  border-right: 1px solid #9d9b9b !important;
}
.ag-header.ag-pivot-off.ag-header-allow-overflow {
  background: #002efd54 !important;
  /* background: #dee3ed !important; */
}


.custom-modal {
  max-width: 800px !important; 
  margin: 0 auto;
}
/* .modal-content{
  width: 850px !important;
} */

.disnone{
  display: none !important;
}



/* -------------------------- */
@media (max-width: 600px) {
  .container123 {
    flex-direction: column;
  }
}


.container123 {
  /* alignment */
  display: flex;
  /* sizes */
  border-radius: 25px;

  /* colors */
  background-color: white;
  box-shadow: 0px 0px 30px 20px #dfdfdf;
}

.container-left {
  /* alignment */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  /* sizes */
  border-radius: 25px;
  width: 380px;
  /* colors */
  background: linear-gradient(hsl(252, 100%, 67%), hsl(241, 81%, 54%) 80%);
}

.title {
  /* font */
  font-size: 22px;
  font-weight: 700;
}

.title-left {
  /* alignment */
  margin: 37px auto;
  /* colors */
  color: rgba(255, 255, 255, 0.692);
}

.circle {
  /* alignment */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
  /* sizes */
  height: 200px;
  width: 200px;
  border-radius: 100%;
  /* colors */
  background: linear-gradient(hsla(256, 72%, 46%, 1), hsla(241, 72%, 46%, 0));
}

.circle-score {
  /* alignment */
  margin-bottom: -6px;
  /* font */
  font-size: 70px;
  font-weight: 800;
  /* colors */
  color: white;
}

.circle-out-of {
  /* font */
  font-weight: 500;
  /* colors */
  color: hsla(0, 0%, 100%, 0.404);
}

.container-left-bottom {
  /* alignment */
  margin: auto 50px;
}

.container-left-bottom-compliment {
  /* alignment */
  margin-bottom: -6px;

  /* font */
  font-weight: 700;
  font-size: 32px;

  /* colors */
  color: white;
}

.container-left-bottom-description {
  /* colors */
  color: hsla(0, 0%, 100%, 0.61);
}

/*
------------
¦right side¦
------------ 
*/

.container-right-box {
  /* alignment */
  display: flex;
  justify-content: center;
}

.container-right {
  /* alignment */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  /* sizes */
  height: 512px;
}

.title-right {
  /* alignment */
  text-align: left;
  margin-top: -3px;
  /* sizes */
  width: 288px;
  /* colors */
  color: hsl(224, 30%, 27%);
}

.stat-box {
  /* alignment */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: -50px;
}

.stat {
  /* font */
  font-size: 18px;
  font-weight: 500;
  /* alignment */
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 70px;
  margin: 8px 40px;
  /* sizes */
  width: 288px;
  height: 55px;
  border-radius: 10px;
}

.stat-part-left {
  /* alignment */
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.stat-part-right {
  /* font */
  font-weight: 700;
  /* colors */
  color: hsla(224, 30%, 27%, 0.514);
}

.stat-reaction {
  background-color: hsla(0, 100%, 67%, 0.068);
}

.stat-verbal {
  background-color: hsl(166, 100%, 37%, 0.068);
}

.stat-visual {
  background-color: hsla(234, 85%, 45%, 0.068);
}

.stat-memory {
  background-color: hsl(39, 100%, 56%, 0.068);
}

.red {
  color: hsl(0, 100%, 67%);
}

.blue {
  color: hsl(224, 30%, 27%);
}

.yellow {
  color: hsl(39, 100%, 56%);
}

.purple {
  color: hsl(234, 85%, 45%);
}

.green {
  color: hsl(166, 100%, 37%);
}


.without-box {
  border: none;
  padding: -3px;
  height: 50px;
  font-size: 21px;
  font-weight: bold;
  cursor: pointer;
  /* margin-left: 60px; */
  font-family: Roboto;
  ;
  font-weight: 500;
}
.img-thumbnail{
  height: 100px !important;
}

.drp-texttile {
  background-color: #134F5C1A;
  padding: 1px 13px 2px 13px;
  border-radius: 25px;
  display: flex;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  color: #333333;
  margin-right: 10px;
  margin-top: 20px;
}
.drp-texttile .drpclose {
  padding-left: 20px;
  cursor: pointer;
}
.css-13cymwt-control{
  height: 50px !important;
}

.select__indicators{
  height: 50px !important;
}




.preloader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999;
}

.dot-loader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: #0230ff;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
}

.preloader p {
  font-size: 1.2rem;
  color: #0230ff;
  /* color: #555; */
  margin-top: 10px;
}

a {
  text-decoration: none !important;
}

.ag-unselectable{
  margin-bottom: 90px !important;
}
.fixed-card {
  height: 200px !important;
  display: flex !important;
  justify-content: center; 
  align-items: center; 
}


input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .dropdown-content {
      position: relative;
      background-color: #f1f1f1;
      min-width: 150px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      z-index: 1;
      margin-top: .5rem !important;
      margin-bottom: 1.5rem !important;
  }
  
  .dropdown-content a {
      padding: 8px 12px;
      text-decoration: none;
      display: block;
      color: black;
      cursor: pointer;
  }
  
  .dropdown-content a:hover {
      background-color: #ddd;
  }
  .filter-btn{
    border-radius: 5px;
    background: #012fff;
    border: none;
    float: right;
    width: 40px;
    height: 40px;
  }

  @media (max-width: 768px) {
    .userlg_img {
      max-width: 250px; 
    }
  }
  @media (max-width: 600px) {
    .userlg_img {
      max-width: 250px; 
    }
  }
  @media (min-width: 1536px) {
    .userlg_img {
      max-width: 250px; 
    }
  }
  @media (max-width: 1199px) {
    .userlg_img {
      max-width: 250px; 
    }
  }
  @media (max-width: 768px) {
    .userlg_img {
      max-width: 250px; 
    }
  }
  @media (max-width: 1200px) {
    .userlg_img {
      max-width: 250px; 
    }
  }
  @media (max-width: 1199px) {
    .userlg_img {
      max-width: 250px; 
    }
  }
  @media (max-width: 992px) {
    .userlg_img {
      max-width: 250px; 
    }
  }